<template>
    <CRow class="justify-content-center">
        <CCol sm="12">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol sm="12">
                            <h4 class="card-title mb-0">Cadastro de Dispositivos Moveis</h4>
                            <div class="small text-muted">Acompanhe suas vendas pelo celular</div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CRow class="align-items-center">
                    </CRow>    
                    <CRow class="align-items-center">
                        <CCol  sm="12" class="mb-3 mb-xl-3">
                            <CRow>
                                <CCol sm="6" md="4" v-if="listMode" >
                                    <CButton color="primary" @click="listMode=false;">Incluir</CButton>
                                </CCol>
                                <CCol sm="6" md="4" v-if="listMode===false">
                                    <CButton color="danger" @click="newData">Voltar</CButton>
                                    &nbsp;
                                    <CButton color="primary" @click="setData">Salvar</CButton>
                                </CCol>                               
                            </CRow>
                        </CCol>
                    </CRow>
                    <CRow class="align-items-center">
                        <CCol sm="12" md="10" lg="6">                        
                            <CDataTable
                                class="mb-0 table-outline"
                                hover
                                head-color="light"
                                :items="mobiles"
                                :fields="tableFields"
                                :noItemsView="noItemsView"
                            >
                                <td slot="name" slot-scope="{item}">{{item.name}}</td>
                                <td slot="action" slot-scope="{item}">
                                    <CButton
                                        size="sm"
                                        :key="'vi'+item.id"
                                        color="danger"
                                        @click="deleteMobile(item.id)"> 
                                        <font-awesome-icon icon="recycle" />
                                    </CButton>
                                </td>
                            </CDataTable>
                        </CCol>
                    </CRow>

                </CCardBody>
            </CCard>
        </CCol>        

    </CRow>

</template>

<script>
import functions from "@/functions";
const axios = require("axios");
const { vhost } = functions;

export default {
    name: 'Mobile',
    data () {
        return {
            mobiles: [],
            listMode: true,
            tableFields: [
                { key: 'name', label: 'Dispositivo' },
                { key: 'action', label: 'Ação', _style: 'width:10%;' }
            ],
            noItemsView:{ 
                noResults: 'Nenhum dispositivo para este filtro', 
                noItems: 'Nenhuma dispositivo cadastrado' 
            },
        }
    },
    methods:{
        async getAllMobiles(){
            const token = localStorage.getItem('user-token');
            let url = vhost('client/mobile')
            console.log('token:', token, 'url:', url)
            await axios({
                method: 'get',
                url,
                headers: { "Content-Type": "application/json","x-access-token": token},
            })
            .then((response) => {
                console.log('response:',response.data)
                this.mobiles = response.data.data
            })
            .catch((error) => {
                console.log('error 1', error.message)
            })


        },
        newData(){
            //
        },
        setData(){

        },
        deleteMobile(){

        }

    },
    mounted(){
        this.getAllMobiles()
    }
}
</script>

